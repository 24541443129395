import React from 'react';

import Contact from '../components/Contact';
import { Page } from '../components/index';

const ContactPage = (props) => {
  return (
    <Page title="Contato" removeFooter>
      <Contact />
    </Page>
  );
};

export default ContactPage;
