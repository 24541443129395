import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import RevealWord from "../RevealWord";

const BackgroundImage = styled(motion.div)`
  width: 100%;
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
  user-select: none;

  ${media.lessThan("medium")`
    .background-image, img {
      height: 100vh;
    }
  `}
`;

const Section = styled.section`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
`;

const Container = styled.main`
  position: relative;
  z-index: 99;
  display: flex;
  flex-flow: column wrap;
`;

const Title = styled.h1`
  line-height: 125px;
  font-size: 80px;
  font-family: "Krona One", sans-serif;
  font-weight: 400;
  letter-spacing: 20px;
  position: relative;
  margin-bottom: 40px;
  text-transform: uppercase;
  width: 100%;

  ${media.lessThan("medium")`
    font-size: 32px;
    width: 100%;
    text-align: center;
    letter-spacing: 10px;

    div {
      width: 100%;
      text-align: center;
    }
  `};

  :after {
    content: "";
    width: 600px;
    height: 135px;
    border: 5px solid #8effac;
    position: absolute;
    top: 0;
    left: -20px;
    pointer-events: none;
    display: none;
  }
`;

const Label = styled.h2`
  font-size: 16px;
  color: #74686e;
  font-weight: 100;
  margin-bottom: 8px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  div {
    color: #74686e;
    text-align: center;
    font-weight: 300;
  }
`;

const LinkToEmail = styled.a`
  font-size: 24px;
  font-family: "Krona One", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 60px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  ${media.lessThan("medium")`
    font-size: 16px;
  `}
`;

const Phone = styled.a`
  font-size: 24px;
  font-family: "Krona One", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  ${media.lessThan("medium")`
    font-size: 16px;
  `}
`;

const Address = styled(motion.div)`
  margin-top: 10vh;

  span {
    font-size: 14px;
    line-height: 24px;

    ${media.lessThan("medium")`
      font-size: 12px;
    `}
  }
`;

const Contact = () => {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "contato-bg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const imageData = data.background.childImageSharp.fluid;

  return (
    <Section>
      <BackgroundImage
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { delay: 0.5, bounce: 0, duration: 2 },
        }}
      >
        <Img fluid={imageData} className="background-image" />
      </BackgroundImage>

      <Container>
        <Title>
          <RevealWord delay={1.1}>Contato</RevealWord>
        </Title>

        <Label>
          <RevealWord delay={1.2}>E-mail</RevealWord>
        </Label>
        <LinkToEmail href="mailto:contato@lab721.com.br">
          <RevealWord delay={1.3}>contato@lab721.com.br</RevealWord>
        </LinkToEmail>

        <Label>
          <RevealWord delay={1.4}>Telefone</RevealWord>
        </Label>
        <Phone href="tel:+5554 32011211">
          <RevealWord delay={1.5}>+55 54 9.8139 9232</RevealWord>
        </Phone>

        <Address
          initial={{ opacity: 0, y: 10 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 1.6, bounce: 0.1 },
          }}
        >
          <span>
            Av. Itália, 277, sala 805
            <br />
            São Pelegrino, Caxias do Sul - RS - Brasil
          </span>
        </Address>
      </Container>
    </Section>
  );
};

export default Contact;
